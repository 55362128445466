<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row fluid>
          <v-col
            cols="12"
            sm="6"
            md="9"
            lg="10"
          >
            Abopläne
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
          >
            <v-btn
              color="primary"
              dark
              block
              @click="openCreateSubscriptionPlanDialog = true"
            >
              Neuer Aboplan
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="allSubscriptionPlans"
        :loading="subscriptionPlansLoading"
        :options="currentSubscriptionPlanTableOptions"
        :server-items-length="subscriptionPlansTotal"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30, 50],
          'items-per-page-text': 'Zeilen pro Seite',
        }"
        :multi-sort="true"
        @update:options="updateTableOptions"
      >
        <template v-slot:[`item.monthlyPrice`]="{ item }">
          <span>{{ item.monthlyPrice | formatMoney }}</span>
        </template>
        <template v-slot:[`item.productDiscount`]="{ item }">
          <span v-if="item.productDiscount">{{ item.productDiscount }}%</span>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.recurring`]="{ item }">
          <v-checkbox v-model="item.recurring" readonly>{{ item.recurring }}</v-checkbox>
        </template>
        <template v-slot:[`item.oneTime`]="{ item }">
          <v-checkbox v-model="item.oneTime" readonly>{{ item.oneTime }}</v-checkbox>
        </template>
        <template v-slot:[`item.bonus`]="{ item }">
          <span v-if="item.bonus">{{ item.bonus }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.archived`]="{ item }">
          <v-checkbox v-model="item.archived" readonly>{{ item.archived }}</v-checkbox>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ item.createdAt | formatDate }}</span>
        </template>
    
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon
                  v-if="!item.archived"
                  class="mr-2"
                  @click="openArchiveDialog(item.id)"
                >
                  archive
                </v-icon>
                <v-icon
                  v-else
                  class="mr-2"
                  @click="openArchiveDialog(item.id)"
                >
                  unarchive
                </v-icon>
              </div>
            </template>
            <span v-if="!item.archived">Aboplan archivieren</span>
            <span v-if="item.archived">Aboplan unarchivieren</span>
          </v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <SubscriptionPlanCreateDialog v-model="openCreateSubscriptionPlanDialog" />
    <SubscriptionPlanArchiveDialog v-model="archiveDialog" />
  </div>
</template>

<script>
import {
  OPEN_SUBSCRIPTION_PLAN,
  SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS,
} from '@/store/storeTypes/mutationTypes';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  SubscriptionPlanCreateDialog,
  SubscriptionPlanArchiveDialog,
} from '../../components/Admin';

export default {
  components: {
    SubscriptionPlanCreateDialog,
    SubscriptionPlanArchiveDialog,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Mindestlaufzeit (30 Tage)',
          sortable: true,
          value: 'minimumTerm',
        },
        {
          text: 'Laufzeit (30 Tage)',
          sortable: true,
          value: 'term',
        },
        {
          text: 'Monatlicher Preis',
          sortable: true,
          value: 'monthlyPrice',
        },
        {
          text: 'Produktrabatt',
          sortable: true,
          value: 'productDiscount',
        },
        {
          text: 'Abschlussbonus',
          sortable: true,
          value: 'bonus',
        },
        {
          text: 'Wiederkehrend',
          sortable: true,
          value: 'recurring',
        },
        {
          text: 'Einmalige Bezahlung',
          sortable: true,
          value: 'oneTime',
        },
        {
          text: 'Archiviert',
          sortable: true,
          value: 'archived',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
      loading: false,
      openCreateSubscriptionPlanDialog: false,
      archiveDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      'allSubscriptionPlans',
      'subscriptionPlansLoading',
      'subscriptionPlansTotal',
      'currentSubscriptionPlan',
      'currentSubscriptionPlanTableOptions',
    ]),
  },

  watch: {
    currentSubscriptionPlanTableOptions: {
      handler() {
        this.findSubscriptionPlansForDataTable();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['findSubscriptionPlans']),
    ...mapMutations(['openSubscriptionPlan', SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS]),
    findSubscriptionPlansForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentSubscriptionPlanTableOptions;

      const sortQuery = {};
      if (sortBy.length) {
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            sortQuery[key] = -1;
          } else {
            sortQuery[key] = +1;
          }
        });
      }

      this.findSubscriptionPlans({
        query: {
          $limit: itemsPerPage,
          $skip: itemsPerPage * (page - 1),
          $sort: sortQuery,
        },
        refresh: true,
      });
    },
    openArchiveDialog(id) {
      this[OPEN_SUBSCRIPTION_PLAN](id);
      this.archiveDialog = true;
    },
    updateTableOptions(options) {
      this[SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS](options);
    },
  },
};
</script>
