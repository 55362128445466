var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8","lg":"9"}},[_vm._v(" Users ")]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allUsers,"loading":_vm.usersLoading,"options":_vm.currentUsersTableOptions,"server-items-length":_vm.usersTotal,"footer-props":{
      'items-per-page-options': [5, 10, 15, 30, 50],
      'items-per-page-text': 'Zeilen pro Seite',
    },"multi-sort":true,"item-class":function () { return 'cursor-pointer'; }},on:{"click:row":_vm.handleClick,"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatRole")(item.role)))])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}},{key:"item.blocked",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.blocked),callback:function ($$v) {_vm.$set(item, "blocked", $$v)},expression:"item.blocked"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }