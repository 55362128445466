var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Produkte ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"clearable":"","dense":"","items":_vm.productTypesSelect,"label":"Typ","item-text":"text","item-value":"value"},model:{value:(_vm.selectedProductType),callback:function ($$v) {_vm.selectedProductType=$$v},expression:"selectedProductType"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"dense":"","items":_vm.allCategories,"label":"Kategorien","item-text":"name","item-value":"id","multiple":""},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","block":""},on:{"click":function($event){_vm.showCreateProductModel = true}}},[_vm._v(" Neues Produkt ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allProducts,"loading":_vm.productsLoading,"options":_vm.currentProductsTableOptions,"server-items-length":_vm.productsTotal,"footer-props":{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      },"multi-sort":true,"item-class":function () { return 'cursor-pointer'; }},on:{"click:row":_vm.handleClick,"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.type === 'movie' ? 'Film' : 'Fotoset'))])]}},{key:"item.files",fn:function(ref){
      var item = ref.item;
return [(item.files)?_c('v-img',{attrs:{"src":_vm.getThumbnail(item.files),"height":"65","width":"115"}}):_vm._e()]}},{key:"item.categories",fn:function(ref){
      var item = ref.item;
return [(item.categories)?_c('div',_vm._l((item.categories),function(categorie,i){return _c('span',{key:categorie.id},[_vm._v(" "+_vm._s(categorie.name)+_vm._s(i !== item.categories.length - 1 ? ', ' : '')+" ")])}),0):_vm._e()]}},{key:"item.models",fn:function(ref){
      var item = ref.item;
return [(item.models)?_c('div',_vm._l((item.models),function(models,i){return _c('span',{key:models.id},[_vm._v(" "+_vm._s(models.name)+_vm._s(i !== item.models.length - 1 ? ', ' : '')+" ")])}),0):_vm._e()]}},{key:"item.pinned",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.pinned),callback:function ($$v) {_vm.$set(item, "pinned", $$v)},expression:"item.pinned"}},[_vm._v(_vm._s(item.pinned))])]}},{key:"item.visable",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.visable),callback:function ($$v) {_vm.$set(item, "visable", $$v)},expression:"item.visable"}},[_vm._v(_vm._s(item.visable))])]}},{key:"item.credits",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.credits))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("productStatus")(item.status)))])]}},{key:"item.editing",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.editing),callback:function ($$v) {_vm.$set(item, "editing", $$v)},expression:"item.editing"}},[_vm._v(_vm._s(item.editing))])]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}}],null,true)})],1),_c('ProductCreateModel',{model:{value:(_vm.showCreateProductModel),callback:function ($$v) {_vm.showCreateProductModel=$$v},expression:"showCreateProductModel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }