var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9","lg":"10"}},[_vm._v(" Kategorien ")]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.openCreateDialog}},[_vm._v(" Neue Kategorie ")])],1)],1),_c('CategorieEditDialog',{attrs:{"action":_vm.editDialogAction},on:{"save":_vm.findCategoriesForDataTable},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('CategorieDeleteDialog',{on:{"remove":_vm.findCategoriesForDataTable},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allCategories,"loading":_vm.categoriesLoading,"options":_vm.currentCategoriesTableOptions,"server-items-length":_vm.categoriesTotal,"footer-props":{
      'items-per-page-options': [5, 10, 15, 30, 50],
      'items-per-page-text': 'Zeilen pro Seite',
    },"multi-sort":true},on:{"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }