<template>
  <v-card>
    <v-card-title>
      <v-row fluid>
        <v-col
          cols="12"
          sm="6"
          md="9"
          lg="10"
        >
          Models
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
        >
          <v-btn
            color="primary"
            dark
            block
            @click="showCreateModelDialog = true"
          >
            Neues Model
          </v-btn>
        </v-col>
      </v-row>
      <ModelCreateDialog v-model="showCreateModelDialog" @created="findModelsForDataTable" />
      <ModelPatchDialog v-model="showPatchModelDialog" />
      <ModelRemoveDialog v-model="showDeleteModelDialog" @deleted="findModelsForDataTable" />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="allModels"
      :loading="modelsLoading"
      :options="currentModlesTableOptions"
      :server-items-length="modelsTotal"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      }"
      :multi-sort="true"
      @update:options="updateTableOptions"
    >
      <template v-slot:[`item.picture`]="{ item }">
        <v-img :src="item.picture" width="70" height="90" />
      </template>
      <template v-slot:[`item.toplessActing`]="{ item }">
        <span>{{ item.toplessActing | modelsOptions }}</span>
      </template>
      <template v-slot:[`item.availableForCustoms`]="{ item }">
        <span>{{ item.availableForCustoms | modelsOptions }}</span>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox v-model="item.active" readonly />
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ item.createdAt | formatDate }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          @click="openEditModelDialog(item.id)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          @click="openDeleteModelDialog(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ModelCreateDialog, ModelPatchDialog, ModelRemoveDialog } from '../../components/Admin';
import { OPEN_MODEL, SET_MODELS_TABLE_OPTIONS } from '../../../store/storeTypes/mutationTypes';

export default {
  filters: {
    modelsOptions(value) {
      if (value === 0) {
        return 'Nein';
      }
      if (value === 1) {
        return 'Auf Anfrage';
      }
      if (value === 2) {
        return 'Ja';
      }

      return '';
    },
  },

  components: {
    ModelCreateDialog,
    ModelPatchDialog,
    ModelRemoveDialog,
  },

  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Bild',
          sortable: false,
          value: 'picture',
        },
        {
          text: 'Bevorzugte Waffe',
          sortable: true,
          value: 'preferredWeapon',
        },
        {
          text: 'Aktivität',
          sortable: true,
          value: 'activityStatus',
        },
        {
          text: 'Topless Acting',
          sortable: true,
          value: 'toplessActing',
        },
        {
          text: 'Verfübar für Customs',
          sortable: true,
          value: 'availableForCustoms',
        },
        {
          text: 'Aktiv',
          sortable: true,
          value: 'active',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
      showCreateModelDialog: false,
      showPatchModelDialog: false,
      showDeleteModelDialog: false,
    };
  },

  computed: {
    ...mapGetters(['allModels', 'modelsLoading', 'modelsTotal', 'currentModlesTableOptions']),
  },

  watch: {
    currentModlesTableOptions: {
      handler() {
        this.findModelsForDataTable();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['findModels', 'getModel', 'createModel', 'patchModel']),
    ...mapMutations([OPEN_MODEL, SET_MODELS_TABLE_OPTIONS]),
    findModelsForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentModlesTableOptions;

      const sortQuery = {};
      if (sortBy.length) {
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            sortQuery[key] = -1;
          } else {
            sortQuery[key] = +1;
          }
        });
      }

      this.findModels({
        query: {
          $limit: itemsPerPage,
          $skip: itemsPerPage * (page - 1),
          $sort: sortQuery,
        },
        refresh: true,
      });
    },
    openEditModelDialog(id) {
      this[OPEN_MODEL](id);
      this.showPatchModelDialog = true;
    },
    openDeleteModelDialog(id) {
      this[OPEN_MODEL](id);
      this.showDeleteModelDialog = true;
    },
    updateTableOptions(options) {
      this[SET_MODELS_TABLE_OPTIONS](options);
    },
  },
};
</script>
