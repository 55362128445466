<template>
  <div v-if="loaded && selectedUser">
    <UserDetailData :selected-user="selectedUser" :active-subscription="activeSubscription" @updateActiveSubscription="updateActiveSubscription" />
    <UserDetailCredis />
    <UserDetailPayments />
    <UserDetailRemoveUser />
  </div>
</template>

<script>
import { OPEN_USER } from '@/store/storeTypes/mutationTypes';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  UserDetailData,
  UserDetailCredis,
  UserDetailPayments,
  UserDetailRemoveUser,
} from '../../components/Admin';

export default {
  components: { UserDetailData, UserDetailCredis, UserDetailPayments, UserDetailRemoveUser },
  data() {
    return {
      loaded: false,
      activeSubscription: null,
    };
  },

  computed: {
    ...mapGetters(['selectedUser', 'allUserCredits', 'userCreditsTotal', 'userCreditsLoading']),
  },

  watch: {
    $route: {
      immediate: true,
      async handler(newVal) {
        const id = Number(newVal.params.id);

        await this.getUser({
          id,
          params: {
            query: {
              $eager: '[maxpaySubscriptions.[subscriptionPlan]]',
            },
          },
        });

        this[OPEN_USER](id);
        if (this.selectedUser.maxpaySubscriptions.length) {
          const activeSubscription = this.selectedUser.maxpaySubscriptions.find(
            (subscription) => subscription.active,
          );
          if (activeSubscription) {
            this.activeSubscription = activeSubscription;
          }
        }

        this.loaded = true;
      },
    },
  },
  methods: {
    ...mapActions(['getUser']),
    ...mapMutations([OPEN_USER]),
    updateActiveSubscription(subscription) {
      this.activeSubscription = subscription;
    },
  },
};
</script>
