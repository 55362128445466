<template>
  <v-card>
    <v-card-title>
      <v-row fluid>
        <v-col
          cols="12"
          sm="6"
          md="9"
          lg="10"
        >
          Kategorien
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
        >
          <v-btn
            block
            color="primary"
            dark
            @click="openCreateDialog"
          >
            Neue Kategorie
          </v-btn>
        </v-col>
      </v-row>
      <CategorieEditDialog 
        v-model="editDialog" 
        :action="editDialogAction"
        @save="findCategoriesForDataTable"
      />
      <CategorieDeleteDialog
        v-model="deleteDialog" 
        @remove="findCategoriesForDataTable"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="allCategories"
      :loading="categoriesLoading"
      :options="currentCategoriesTableOptions"
      :server-items-length="categoriesTotal"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      }"
      :multi-sort="true"
      @update:options="updateTableOptions"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ item.createdAt | formatDate }}</span>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox v-model="item.active" readonly />
      </template>
    
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  OPEN_CATEGORY,
  SET_CATEGORIES_DATA_TABLE_OPTIONS,
} from '../../../store/storeTypes/mutationTypes';
import { CategorieEditDialog, CategorieDeleteDialog } from '../../components/Admin';

export default {
  components: {
    CategorieEditDialog,
    CategorieDeleteDialog,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Aktiv',
          sortable: true,
          value: 'active',
        },
        {
          text: 'Priorität',
          sortable: true,
          value: 'priority',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
      loading: false,
      editDialog: false,
      editDialogAction: '',
      deleteDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      'allCategories',
      'categoriesLoading',
      'categoriesTotal',
      'currentCategory',
      'currentCategoriesTableOptions',
    ]),
  },

  watch: {
    currentCategoriesTableOptions: {
      handler() {
        this.findCategoriesForDataTable();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['findCategories', 'createCategorie', 'patchCategorie', 'removeCategorie']),
    ...mapMutations([SET_CATEGORIES_DATA_TABLE_OPTIONS]),
    findCategoriesForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentCategoriesTableOptions;

      const sortQuery = {};
      if (sortBy.length) {
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            sortQuery[key] = -1;
          } else {
            sortQuery[key] = +1;
          }
        });
      }

      this.findCategories({
        query: {
          $limit: itemsPerPage,
          $skip: itemsPerPage * (page - 1),
          $sort: sortQuery,
        },
        refresh: true,
      });
    },
    openCreateDialog() {
      this.editDialogAction = 'create';
      this.editDialog = true;
    },
    openEditDialog(item) {
      this.editDialogAction = 'patch';
      this.$store.commit(OPEN_CATEGORY, item.id);
      this.editDialog = true;
    },
    openDeleteDialog(categorie) {
      this.$store.commit(OPEN_CATEGORY, categorie.id);
      this.deleteDialog = true;
    },
    updateTableOptions(options) {
      this[SET_CATEGORIES_DATA_TABLE_OPTIONS](options);
    },
  },
};
</script>
