<template>
  <v-card>
    <v-card-title>
      <v-row fluid>
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="9"
        >
          Altersverifikationen
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="allAgeVerifications"
      :loading="ageVerificationsLoading"
      :options="currentAgeVerificationsTableOptions"
      :server-items-length="ageVerificationsTotal"
      :item-class="() => 'cursor-pointer'"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      }"
      @click:row="handleClick"
      @update:options="updateTableOptions"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ item.createdAt | formatDate }}</span>
      </template>
      <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  OPEN_AGE_VERIFICATION,
  SET_AGE_VERIFICATIONS_TABLE_OPTIONS,
} from '../../../store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      headers: [
        {
          text: 'Vorname',
          align: 'start',
          sortable: false,
          value: 'user.firstName',
        },
        {
          text: 'Nachname',
          sortable: false,
          value: 'user.lastName',
        },
        {
          text: 'E-Mail',
          sortable: false,
          value: 'user.email',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'allAgeVerifications',
      'ageVerificationsLoading',
      'ageVerificationsTotal',
      'currentAgeVerification',
      'currentAgeVerificationsTableOptions',
    ]),
  },

  watch: {
    currentAgeVerificationsTableOptions: {
      handler() {
        this.findAgeVerificationsForDataTable();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['findAgeVerifications']),
    findAgeVerificationsForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentAgeVerificationsTableOptions;

      const sortQuery = {};
      if (sortBy.length) {
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            sortQuery[key] = -1;
          } else {
            sortQuery[key] = +1;
          }
        });
      }

      this.findAgeVerifications({
        query: {
          $eager: '[user]',
          $limit: itemsPerPage,
          $skip: itemsPerPage * (page - 1),
          $sort: sortQuery,
          uploaded: 1,
          status: 'pending',
        },
        refresh: true,
      });
    },
    handleClick(e) {
      this.$store.commit(OPEN_AGE_VERIFICATION, e.id);
      this.$router.push(`/age-verification/${e.id}`);
    },
    updateTableOptions(options) {
      this.$store.commit(SET_AGE_VERIFICATIONS_TABLE_OPTIONS, options);
    },
  },
};
</script>
