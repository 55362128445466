<template>
  <v-card>
    <v-card-title>
      <v-row fluid>
        <v-col
          cols="12"
          sm="6"
          md="9"
          lg="10"
        >
          Bundles
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
        >
          <v-btn
            color="primary"
            dark
            block
            @click="openCreateDialog"
          >
            Neues Bundle
          </v-btn>
        </v-col>
      </v-row>
      <BundleEditDialog 
        v-model="editDialog" 
        :action="editDialogAction" 
        @save="findBundlesForDataTable"
      />
      <BundleDeleteDialog v-model="deleteDialog" @delete="findBundlesForDataTable" />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="allBundleProducts"
      :loading="bundleProductsLoading"
      :options="currentBundleTableOptions"
      :server-items-length="bundleProductsTotal"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      }"
      :multi-sort="true"
      @update:options="updateTableOptions"
    >
      <template v-slot:[`item.discount`]="{ item }">
        <span>{{ item.discount }}%</span>
      </template>
      <template v-slot:[`item.bundleProducts`]="{ item }">
        <span v-for="(product, i) in item.bundleProducts" :key="product.id">
          {{ product.title }}{{ i !== item.bundleProducts.length - 1 ? ', ' : '' }}
        </span>
      </template>
      <template v-slot:[`item.categories`]="{ item }">
        <div v-if="item.categories">
          <span v-for="(categorie, i) in item.categories" :key="categorie.id">
            {{ categorie.name }}{{ i !== item.categories.length - 1 ? ', ' : '' }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.pinned`]="{ item }">
        <v-checkbox v-model="item.pinned" readonly>{{ item.pinned }}</v-checkbox>
      </template>
      <template v-slot:[`item.visable`]="{ item }">
        <v-checkbox v-model="item.visable" readonly>{{ item.visable }}</v-checkbox>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ item.createdAt | formatDate }}</span>
      </template>
    
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>
        <!-- <v-icon
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon> -->
      </template>
      <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  OPEN_BUNDLE_PRODUCT,
  SET_BUNDLES_DATA_TABLE_OPTIONS,
} from '../../../store/storeTypes/mutationTypes';
import { BundleDeleteDialog, BundleEditDialog } from '../../components/Admin';

export default {
  components: {
    BundleDeleteDialog,
    BundleEditDialog,
  },
  data() {
    return {
      headers: [
        {
          text: 'Titel',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Discount',
          align: 'start',
          sortable: true,
          value: 'discount',
        },
        {
          text: 'Produkte',
          sortable: false,
          value: 'bundleProducts',
        },
        {
          text: 'Kategorien',
          sortable: false,
          value: 'categories',
        },
        {
          text: 'Pinned',
          sortable: true,
          value: 'pinned',
        },
        {
          text: 'Sichtbar',
          sortable: true,
          value: 'visable',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ],
      editDialog: false,
      editDialogAction: '',
      deleteDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      'allBundleProducts',
      'bundleProductsLoading',
      'bundleProductsTotal',
      'currentBundleTableOptions',
    ]),
  },

  watch: {
    currentBundleTableOptions: {
      handler() {
        this.findBundlesForDataTable();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['findBundleProducts']),
    ...mapMutations([SET_BUNDLES_DATA_TABLE_OPTIONS]),

    findBundlesForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentBundleTableOptions;

      const findQuery = {
        $limit: itemsPerPage,
        $skip: itemsPerPage * (page - 1),
        $eager: {
          categories: true,
          bundleProducts: true,
        },
      };

      if (sortBy.length) {
        findQuery.$sort = {};
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            findQuery.$sort[key] = -1;
          } else {
            findQuery.$sort[key] = +1;
          }
        });
      }

      this.findBundleProducts({
        query: findQuery,
        refresh: true,
      });
    },

    openCreateDialog() {
      this.editDialogAction = 'create';
      this.editDialog = true;
    },

    openEditDialog(bundle) {
      this.editDialogAction = 'patch';
      this.$store.commit(OPEN_BUNDLE_PRODUCT, bundle.id);
      this.editDialog = true;
    },

    openDeleteDialog(categorie) {
      this.$store.commit(OPEN_BUNDLE_PRODUCT, categorie.id);
      this.deleteDialog = true;
    },
    updateTableOptions(options) {
      this[SET_BUNDLES_DATA_TABLE_OPTIONS](options);
    },
  },
};
</script>
