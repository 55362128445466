<template>
  <div v-if="loaded">
    <v-row>
      <v-col md="8">
        <v-card>
          <v-container fluid>
            <v-img :src="currentAgeVerification.image" />
          </v-container>
    
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="printImage"
            >
              Ausdrucken
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card class="sticky top-4">
          <v-card-title>
            Altersverifikation
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div>Vorname: {{ currentAgeVerification.user.firstName }}</div>
            <div>Nachname: {{ currentAgeVerification.user.lastName }}</div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="reveal = true">Ablehnen</v-btn>
            <v-btn text @click="handleAcceptButton">Annehmen</v-btn>
          </v-card-actions>

          <v-expand-transition>
            <v-card
              v-if="reveal"
              class="transition-fast-in-fast-out absolute bottom-0 w-full opacity-100 h-full"
            >
              <v-card-title>Ablehnen</v-card-title>
              <v-card-text class="pt-0 pb-0">
                <v-text-field
                  v-model="rejectionReason"
                  label="Ablehnungsgrund"
                />
              </v-card-text>
              <v-card-actions class="">
                <v-spacer />
                <v-btn text @click="reveal = false">
                  Abbrechen
                </v-btn>
                <v-btn text @click="handleRejectButton">
                  Abschicken
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      absolute
      bottom
    >
      {{ snackbarText }}

      <template v-slot:action="{}">
        <v-icon color="success">done</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { OPEN_AGE_VERIFICATION } from '@/store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      reveal: false,
      rejectionReason: '',
      loaded: false,
      snackbar: false,
      snackbarText: '',
    };
  },

  computed: {
    ...mapGetters(['currentAgeVerification', 'ageVerificationsLoading']),
  },

  watch: {
    $route: {
      immediate: true,
      async handler(newVal) {
        const id = Number(newVal.params.id);
        this[OPEN_AGE_VERIFICATION](id);

        if (this.currentAgeVerification === null) {
          await this.getAgeVerification({
            id,
            params: {
              query: {
                $eager: '[user]',
                uploaded: 1,
                status: 'pending',
              },
            },
          });
        }

        this.loaded = true;
      },
    },
  },

  methods: {
    ...mapMutations([OPEN_AGE_VERIFICATION]),
    ...mapActions(['getAgeVerification', 'acceptAgeVerification', 'rejectAgeVerification']),

    async handleAcceptButton() {
      await this.acceptAgeVerification({
        id: this.currentAgeVerification.id,
        params: {
          query: {
            $eager: '[user]',
          },
        },
      });
      this.snackbarText = 'Altersverifikation angenommen';
      this.snackbar = true;
      setTimeout(() => {
        this.$router.push('/age-verifications');
      }, 1500);
    },

    async handleRejectButton() {
      await this.rejectAgeVerification({
        id: this.currentAgeVerification.id,
        rejectionReason: this.rejectionReason,
        params: {
          query: {
            $eager: '[user]',
          },
        },
      });

      this.snackbarText = 'Altersverifikation abgelehnt';
      this.snackbar = true;
      setTimeout(() => {
        this.$router.push('/age-verifications');
      }, 1500);
    },

    printImage() {
      const win = window.open('about:blank', '_new');
      if (win) {
        win.document.open();
        win.document.write(`
        <html>
          <head></head>
          <body onload="window.print()" onafterprint="window.close()">
            <img src="${this.currentAgeVerification.image}"/>
          </body>
        '</html>
      `);
        win.document.close();
      }
    },
  },
};
</script>
