<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row fluid>
          <v-col cols="12" md="4">
            Produkte
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedProductType"
              clearable
              dense
              :items="productTypesSelect"
              label="Typ"
              item-text="text"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedCategories"
              dense
              :items="allCategories"
              label="Kategorien"
              item-text="name"
              item-value="id"
              multiple
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="search"
              dense
              
              append-icon="mdi-magnify"
              label="Suchen"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              color="primary"
              dark
              block
              @click="showCreateProductModel = true"
            >
              Neues Produkt
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="allProducts"
        :loading="productsLoading"
        :options="currentProductsTableOptions"
        :server-items-length="productsTotal"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30, 50],
          'items-per-page-text': 'Zeilen pro Seite',
        }"
        :multi-sort="true"
        :item-class="() => 'cursor-pointer'"
        @click:row="handleClick"
        @update:options="updateTableOptions"
      >
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ item.type === 'movie' ? 'Film' : 'Fotoset' }}</span>
        </template>
        <template v-slot:[`item.files`]="{ item }">
          <v-img
            v-if="item.files"
            :src="getThumbnail(item.files)"
            height="65"
            width="115"
          />
        </template>
        <template v-slot:[`item.categories`]="{ item }">
          <div v-if="item.categories">
            <span v-for="(categorie, i) in item.categories" :key="categorie.id">
              {{ categorie.name }}{{ i !== item.categories.length - 1 ? ', ' : '' }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.models`]="{ item }">
          <div v-if="item.models">
            <span v-for="(models, i) in item.models" :key="models.id">
              {{ models.name }}{{ i !== item.models.length - 1 ? ', ' : '' }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.pinned`]="{ item }">
          <v-checkbox v-model="item.pinned" readonly>{{ item.pinned }}</v-checkbox>
        </template>
        <template v-slot:[`item.visable`]="{ item }">
          <v-checkbox v-model="item.visable" readonly>{{ item.visable }}</v-checkbox>
        </template>
        <template v-slot:[`item.credits`]="{ item }">
          <span>{{ item.credits }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | productStatus }}</span>
        </template>
        <template v-slot:[`item.editing`]="{ item }">
          <v-checkbox v-model="item.editing" readonly>{{ item.editing }}</v-checkbox>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ item.createdAt | formatDate }}</span>
        </template>
        <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <ProductCreateModel v-model="showCreateProductModel" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  OPEN_PRODUCT,
  SET_PRODUCTS_DATA_TABLE_OPTIONS,
  SET_PRODUCTS_FILTER_OPTIONS,
} from '@/store/storeTypes/mutationTypes';
import { ProductCreateModel } from '../../components/Admin';

export default {
  components: {
    ProductCreateModel,
  },
  filters: {
    productStatus(status) {
      switch (status) {
        case 'processed':
          return 'Verarbeitet';
        case 'processing':
          return 'Am verarbeiten';
        case 'failed':
          return 'Fehlgeschlagen';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Titel',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Typ',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Thumbnail',
          sortable: false,
          value: 'files',
        },
        {
          text: 'Kategorien',
          sortable: false,
          value: 'categories',
        },
        {
          text: 'Models',
          sortable: false,
          value: 'models',
        },
        {
          text: 'Pinned',
          sortable: true,
          value: 'pinned',
        },
        {
          text: 'Sichtbar',
          sortable: true,
          value: 'visable',
        },
        {
          text: 'Credits',
          sortable: true,
          value: 'credits',
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
        },
        {
          text: 'In Bearbeitung',
          sortable: true,
          value: 'editing',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
      ],
      productTypesSelect: [
        { value: 'movie', text: 'Film' },
        { value: 'fotoset', text: 'Fotoset' },
      ],
      showCreateProductModel: false,
      showTableContent: false,
    };
  },

  computed: {
    ...mapGetters([
      'allProducts',
      'productsLoading',
      'productsTotal',
      'allCategories',
      'currentProductsTableOptions',
      'currentProductsFilterOptions',
    ]),
    search: {
      get() {
        return this.currentProductsFilterOptions.search;
      },
      set(value) {
        this[SET_PRODUCTS_FILTER_OPTIONS]({
          ...this.currentProductsFilterOptions,
          search: value,
        });
      },
    },
    selectedProductType: {
      get() {
        return this.currentProductsFilterOptions.selectedProductType;
      },
      set(value) {
        this[SET_PRODUCTS_FILTER_OPTIONS]({
          ...this.currentProductsFilterOptions,
          selectedProductType: value,
        });
        this.findProductsForDataTable();
      },
    },
    selectedCategories: {
      get() {
        return this.currentProductsFilterOptions.selectedCategories;
      },
      set(value) {
        this[SET_PRODUCTS_FILTER_OPTIONS]({
          ...this.currentProductsFilterOptions,
          selectedCategories: value,
        });
        this.findProductsForDataTable();
      },
    },
  },

  watch: {
    currentProductsTableOptions: {
      handler() {
        this.findProductsForDataTable();
      },
      deep: true,
    },
    search: 'searchTimeOut',
  },
  async created() {
    await this.loadAllCategories({
      query: {
        $limit: 150,
      },
    });

    if (
      this.currentProductsFilterOptions.selectedCategories &&
      this.currentProductsFilterOptions.selectedCategories.length
    ) {
      const newSelectedCategories = this.currentProductsFilterOptions.selectedCategories;
      let foundDeletedCategorie = false;

      this.currentProductsFilterOptions.selectedCategories.forEach((selectedCategorie, i) => {
        if (!this.allCategories.some((categorie) => categorie.id === selectedCategorie)) {
          newSelectedCategories.splice(i, 1);
          foundDeletedCategorie = true;
        }
      });

      if (foundDeletedCategorie) {
        this[SET_PRODUCTS_FILTER_OPTIONS]({
          ...this.currentProductsFilterOptions,
          selectedCategories: newSelectedCategories,
        });
        this.findProductsForDataTable();
      }
    }
  },

  methods: {
    ...mapActions(['findProducts', 'loadAllCategories']),
    ...mapMutations(['openProduct', SET_PRODUCTS_DATA_TABLE_OPTIONS, SET_PRODUCTS_FILTER_OPTIONS]),
    findProductsForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentProductsTableOptions;

      const findQuery = {
        $limit: itemsPerPage,
        $skip: itemsPerPage * (page - 1),
        $eager: {
          files: {
            types: true,
          },
          categories: true,
          models: true,
        },
        type: {
          $ne: 'bundle',
        },
        onlyThumbnails: true,
      };

      if (sortBy.length) {
        findQuery.$sort = {};
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            findQuery.$sort[key] = -1;
          } else {
            findQuery.$sort[key] = +1;
          }
        });
      }

      if (this.search) {
        findQuery.$or = [
          { title: { $like: `%${this.search}%` } },
          { keywords: { $like: `%${this.search}%` } },
          { producer: { $like: `%${this.search}%` } },
        ];
      }

      if (this.selectedProductType) {
        findQuery.type = this.selectedProductType;
      }

      if (this.selectedCategories.length) {
        findQuery['categories.id'] = {
          $in: this.selectedCategories,
        };
        findQuery.$joinRelation = 'categories';
      }

      this.findProducts({
        query: findQuery,
        refresh: true,
      });

      this.showTableContent = true;
    },

    handleClick(product) {
      this[OPEN_PRODUCT](product.id);
      this.$router.push(`/products/${product.id}`);
    },

    getThumbnail(files) {
      const thumbnailFile = files.find((file) =>
        file.types.some((type) => type.name === 'thumbnail'),
      );

      return thumbnailFile ? thumbnailFile.url : null;
    },

    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.currentProductsTableOptions.page !== 1) {
          this[SET_PRODUCTS_DATA_TABLE_OPTIONS]({
            ...this.currentProductsTableOptions,
            page: 1,
          });
        } else {
          this.findProductsForDataTable();
        }
      }, 275);
    },
    updateTableOptions(options) {
      this[SET_PRODUCTS_DATA_TABLE_OPTIONS](options);
    },
  },
};
</script>
