var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9","lg":"10"}},[_vm._v(" Abopläne ")]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","block":""},on:{"click":function($event){_vm.openCreateSubscriptionPlanDialog = true}}},[_vm._v(" Neuer Aboplan ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allSubscriptionPlans,"loading":_vm.subscriptionPlansLoading,"options":_vm.currentSubscriptionPlanTableOptions,"server-items-length":_vm.subscriptionPlansTotal,"footer-props":{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      },"multi-sort":true},on:{"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.monthlyPrice",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(item.monthlyPrice)))])]}},{key:"item.productDiscount",fn:function(ref){
      var item = ref.item;
return [(item.productDiscount)?_c('span',[_vm._v(_vm._s(item.productDiscount)+"%")]):_c('span',[_vm._v("-")])]}},{key:"item.recurring",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.recurring),callback:function ($$v) {_vm.$set(item, "recurring", $$v)},expression:"item.recurring"}},[_vm._v(_vm._s(item.recurring))])]}},{key:"item.oneTime",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.oneTime),callback:function ($$v) {_vm.$set(item, "oneTime", $$v)},expression:"item.oneTime"}},[_vm._v(_vm._s(item.oneTime))])]}},{key:"item.bonus",fn:function(ref){
      var item = ref.item;
return [(item.bonus)?_c('span',[_vm._v(_vm._s(item.bonus))]):_c('span',[_vm._v("-")])]}},{key:"item.archived",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.archived),callback:function ($$v) {_vm.$set(item, "archived", $$v)},expression:"item.archived"}},[_vm._v(_vm._s(item.archived))])]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!item.archived)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openArchiveDialog(item.id)}}},[_vm._v(" archive ")]):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openArchiveDialog(item.id)}}},[_vm._v(" unarchive ")])],1)]}}],null,true)},[(!item.archived)?_c('span',[_vm._v("Aboplan archivieren")]):_vm._e(),(item.archived)?_c('span',[_vm._v("Aboplan unarchivieren")]):_vm._e()])]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}}],null,true)})],1),_c('SubscriptionPlanCreateDialog',{model:{value:(_vm.openCreateSubscriptionPlanDialog),callback:function ($$v) {_vm.openCreateSubscriptionPlanDialog=$$v},expression:"openCreateSubscriptionPlanDialog"}}),_c('SubscriptionPlanArchiveDialog',{model:{value:(_vm.archiveDialog),callback:function ($$v) {_vm.archiveDialog=$$v},expression:"archiveDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }