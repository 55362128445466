<template>
  <div v-if="loaded">
    <v-row>
      <v-col cols="12" lg="9">
        <v-row>
          <v-col cols="12" lg="6">
            <ProductDetailProperties />
          </v-col>
          <v-col cols="12" lg="6">
            <ProductDetailTranslations />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="3">
        <v-row>
          <v-col cols="12">
            <ProductDetailCategories />
          </v-col>
          <v-col cols="12">
            <ProductDetailModels />
          </v-col>
          <v-col cols="12">
            <ProductDetailBundles />
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="12">
        <ProductDetailFiles v-model="uploadingFile" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { OPEN_PRODUCT } from '../../../store/storeTypes/mutationTypes';
import {
  ProductDetailProperties,
  ProductDetailTranslations,
  ProductDetailCategories,
  ProductDetailModels,
  ProductDetailBundles,
  ProductDetailFiles,
} from '../../components/Admin';

export default {
  components: {
    ProductDetailProperties,
    ProductDetailTranslations,
    ProductDetailCategories,
    ProductDetailModels,
    ProductDetailBundles,
    ProductDetailFiles,
  },
  data() {
    return {
      loaded: false,
      product: {},
      uploadingFile: false,
    };
  },

  computed: {
    ...mapGetters(['currentProduct']),
  },

  watch: {
    $route: {
      immediate: true,
      async handler(newVal) {
        this.listenToProductEvents();
        const id = Number(newVal.params.id);

        await this.getProduct({
          id,
          params: {
            query: {
              $eager: {
                models: true,
                categories: true,
                translations: true,
                bundles: {
                  bundleProducts: true,
                },
              },
            },
          },
        });
        this[OPEN_PRODUCT](id);

        this.product = JSON.parse(JSON.stringify(this.currentProduct));
        this.loaded = true;
      },
    },
  },

  methods: {
    ...mapActions(['findProducts', 'findCategories', 'getProduct', 'listenToProductEvents']),
    ...mapMutations(['openProduct']),
  },

  beforeRouteLeave(to, from, next) {
    if (this.uploadingFile) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(
        'Es wird derzeit noch eine Datei hochgeladen. Warten Sie bitte bis diese hochgeladen ist!',
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.currentProduct.editing) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(
        'Das Produkt wird noch bearbeitet. Beenden Sie die Bearbeitung falls Sie fertig sind.',
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
