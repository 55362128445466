<template>
  <v-card>
    <v-card-title>
      <v-row fluid> 
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="9"
        >
          Users
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Suchen"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="allUsers"
      :loading="usersLoading"
      :options="currentUsersTableOptions"
      :server-items-length="usersTotal"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 30, 50],
        'items-per-page-text': 'Zeilen pro Seite',
      }"
      :multi-sort="true"
      :item-class="() => 'cursor-pointer'"
      @click:row="handleClick"
      @update:options="updateTableOptions"
    > 
      <template v-slot:[`item.role`]="{ item }">
        <span>{{ item.role | formatRole }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ item.createdAt | formatDate }}</span>
      </template>
      <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
      <template v-slot:[`item.blocked`]="{ item }">
        <v-checkbox v-model="item.blocked" readonly />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  OPEN_USER,
  SET_USERS_TABLE_OPTIONS,
  SET_USERS_FILTER_OPTIONS,
} from '../../../store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'E-Mail',
          sortable: true,
          value: 'email',
        },
        {
          text: 'Vorname',
          sortable: true,
          value: 'firstName',
        },
        {
          text: 'Nachname',
          sortable: true,
          value: 'lastName',
        },
        {
          text: 'Rolle',
          sortable: true,
          value: 'role',
        },
        {
          text: 'Blockiert',
          sortable: true,
          value: 'blocked',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'allUsers',
      'usersLoading',
      'usersTotal',
      'selectedUser',
      'currentUsersTableOptions',
      'currentUsersFilterOptions',
    ]),
    search: {
      get() {
        return this.currentUsersFilterOptions.search;
      },
      set(value) {
        this[SET_USERS_FILTER_OPTIONS]({
          ...this.currentUsersFilterOptions,
          search: value,
        });
      },
    },
  },

  watch: {
    currentUsersTableOptions: {
      handler() {
        this.findUsersForDataTable();
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.searchTimeOut(val);
      },
    },
  },

  methods: {
    ...mapActions(['findUsers']),
    ...mapMutations([SET_USERS_TABLE_OPTIONS, SET_USERS_FILTER_OPTIONS]),
    findUsersForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.currentUsersTableOptions;

      const findQuery = {
        $limit: itemsPerPage,
        $skip: itemsPerPage * (page - 1),
        deleted: 0,
        role: { $ne: 'admin' },
      };

      if (sortBy.length) {
        findQuery.$sort = {};
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            findQuery.$sort[key] = -1;
          } else {
            findQuery.$sort[key] = +1;
          }
        });
      }

      if (this.search) {
        findQuery.$or = [
          { id: { $like: `%${this.search}%` } },
          { email: { $like: `%${this.search}%` } },
          { firstName: { $like: `%${this.search}%` } },
          { lastName: { $like: `%${this.search}%` } },
        ];
      }

      this.findUsers({
        query: findQuery,
        refresh: true,
      });
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.currentUsersTableOptions.page !== 1) {
          this[SET_USERS_TABLE_OPTIONS]({
            ...this.currentUsersTableOptions,
            page: 1,
          });
        } else {
          this.findUsersForDataTable();
        }
      }, 275);
    },
    handleClick(user) {
      this.$store.commit(OPEN_USER, user.id);
      this.$router.push(`/users/${user.id}`);
    },
    updateTableOptions(options) {
      this[SET_USERS_TABLE_OPTIONS](options);
    },
  },
};
</script>
