var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9","lg":"10"}},[_vm._v(" Models ")]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","block":""},on:{"click":function($event){_vm.showCreateModelDialog = true}}},[_vm._v(" Neues Model ")])],1)],1),_c('ModelCreateDialog',{on:{"created":_vm.findModelsForDataTable},model:{value:(_vm.showCreateModelDialog),callback:function ($$v) {_vm.showCreateModelDialog=$$v},expression:"showCreateModelDialog"}}),_c('ModelPatchDialog',{model:{value:(_vm.showPatchModelDialog),callback:function ($$v) {_vm.showPatchModelDialog=$$v},expression:"showPatchModelDialog"}}),_c('ModelRemoveDialog',{on:{"deleted":_vm.findModelsForDataTable},model:{value:(_vm.showDeleteModelDialog),callback:function ($$v) {_vm.showDeleteModelDialog=$$v},expression:"showDeleteModelDialog"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allModels,"loading":_vm.modelsLoading,"options":_vm.currentModlesTableOptions,"server-items-length":_vm.modelsTotal,"footer-props":{
      'items-per-page-options': [5, 10, 15, 30, 50],
      'items-per-page-text': 'Zeilen pro Seite',
    },"multi-sort":true},on:{"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
    var item = ref.item;
return [_c('v-img',{attrs:{"src":item.picture,"width":"70","height":"90"}})]}},{key:"item.toplessActing",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("modelsOptions")(item.toplessActing)))])]}},{key:"item.availableForCustoms",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("modelsOptions")(item.availableForCustoms)))])]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openEditModelDialog(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteModelDialog(item.id)}}},[_vm._v(" mdi-delete ")])]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }